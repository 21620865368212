import {errorAlert, infoAlert, warnAlert} from '../actions/alertsActions';
import {
    CONNECT_ADMIN_WEBSOCKET_PENDING,
    CONNECT_ADMIN_WEBSOCKET_SUCCESS,
    CONNECT_APIGEE_WEBSOCKET_PENDING,
    CONNECT_APIGEE_WEBSOCKET_SUCCESS,
    CONNECT_DISTRIBUTION_WEBSOCKET_PENDING,
    CONNECT_COMMERCIAL_WEBSOCKET_PENDING,
    CONNECT_DISTRIBUTION_WEBSOCKET_SUCCESS,
    CONNECT_COMMERCIAL_WEBSOCKET_SUCCESS,
    CONNECT_MARKETING_WEBSOCKET_PENDING, CONNECT_MARKETING_WEBSOCKET_SUCCESS
} from '../actions/actionWebsocket';
import {distributionWebsocketCommands} from '../constants/distributionWebsocketCommands';
import errorMessages from '../intl/common/errorMessages';
import warnMessages from '../intl/common/warnMessages';
import alertMessages from '../intl/common/alertMessages';
import {authenticateAndRedirect, getAuthenticatedUser} from '../utils/auth';
import buttonMessages from '../intl/common/buttonMessages';
import {modules} from '../constants/Utils';
import get from 'get-value';
import FileSaver from 'file-saver';
import {commercialWebsocketCommands} from '../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../constants/marketingWebsocketCommands';
import {axiosFileProgressInstance} from '../api/rest';

const infoAlertUniqueId = '3466b8ae-0855-43a1-bd7c-257d2a79a3f8';
const webSockets = {};

export const createAdminSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_ADMIN_WEBSOCKET_PENDING) {
            webSockets[modules.ADMINISTRATION] = new WebSocket(`${process.env.REACT_APP_BE_ADMIN_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.ADMINISTRATION].onopen = () => dispatch({type: CONNECT_ADMIN_WEBSOCKET_SUCCESS});
            webSockets[modules.ADMINISTRATION].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.ADMINISTRATION_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : alertMessages.ADMINISTRATION_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.ADMINISTRATION].onclose = async () =>
                webSockets[modules.ADMINISTRATION] = await reinitializeClosedSocket(webSockets[modules.ADMINISTRATION], process.env.REACT_APP_BE_ADMIN_WS_URL, action, dispatch);
        }
        if (action.adminWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.ADMINISTRATION, alertMessages.ADMINISTRATION_BE_UNREACHABLE);
        }
        return next(action);
    }
};

export const createDistributionSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_DISTRIBUTION_WEBSOCKET_PENDING) {
            console.log(`${process.env.REACT_APP_BE_DISTRIBUTION_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.DISTRIBUTION] = new WebSocket(`${process.env.REACT_APP_BE_DISTRIBUTION_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.DISTRIBUTION].onopen = () => dispatch({type: CONNECT_DISTRIBUTION_WEBSOCKET_SUCCESS});
            webSockets[modules.DISTRIBUTION].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.DISTRIBUTION_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : alertMessages.DISTRIBUTION_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    if (messageObj.command === distributionWebsocketCommands.DISTRIBUTION_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === distributionWebsocketCommands.DISTRIBUTION_EXPORT_DOWNLOAD) {
                        const {url, type} = messageObj.exportRecord;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, `export.${type}`),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.DISTRIBUTION].onclose = async () =>
                webSockets[modules.DISTRIBUTION] = await reinitializeClosedSocket(webSockets[modules.DISTRIBUTION], process.env.REACT_APP_BE_DISTRIBUTION_WS_URL, action, dispatch);
        }
        if (action.distributionWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.DISTRIBUTION, alertMessages.DISTRIBUTION_BE_UNREACHABLE);
        }
        return next(action);
    }
};

export const createCommercialSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_COMMERCIAL_WEBSOCKET_PENDING) {
            console.log(`${process.env.REACT_APP_BE_COMMERCIAL_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.COMMERCIAL] = new WebSocket(`${process.env.REACT_APP_BE_COMMERCIAL_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.COMMERCIAL].onopen = () => dispatch({type: CONNECT_COMMERCIAL_WEBSOCKET_SUCCESS});
            webSockets[modules.COMMERCIAL].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.COMMERCIAL_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : alertMessages.COMMERCIAL_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    if (messageObj.command === commercialWebsocketCommands.COMMERCIAL_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === commercialWebsocketCommands.COMMERCIAL_EXPORT_DOWNLOAD) {
                        const {url, type} = messageObj.exportRecord;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, `export.${type}`),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.COMMERCIAL].onclose = async () =>
                webSockets[modules.COMMERCIAL] = await reinitializeClosedSocket(webSockets[modules.COMMERCIAL], process.env.REACT_APP_BE_COMMERCIAL_WS_URL, action, dispatch);
        }
        if (action.commercialWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.COMMERCIAL, alertMessages.COMMERCIAL_BE_UNREACHABLE);
        }
        return next(action);
    }
};

export const createMarketingSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_MARKETING_WEBSOCKET_PENDING) {
            console.log(`${process.env.REACT_APP_BE_MARKETING_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.MARKETING] = new WebSocket(`${process.env.REACT_APP_BE_MARKETING_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.MARKETING].onopen = () => dispatch({type: CONNECT_MARKETING_WEBSOCKET_SUCCESS});
            webSockets[modules.MARKETING].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.MARKETING_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : alertMessages.MARKETING_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    if (messageObj.command === marketingWebsocketCommands.MARKETING_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === marketingWebsocketCommands.MARKETING_EXPORT_DOWNLOAD) {
                        const {url, type} = messageObj.exportRecord;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, `export.${type}`),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.MARKETING].onclose = async () =>
                webSockets[modules.MARKETING] = await reinitializeClosedSocket(webSockets[modules.MARKETING], process.env.REACT_APP_BE_MARKETING_WS_URL, action, dispatch);
        }
        if (action.marketingWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.MARKETING, alertMessages.MARKETING_BE_UNREACHABLE);
        }
        return next(action);
    }
};

export const createApigeeSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_APIGEE_WEBSOCKET_PENDING) {
            console.log(`${process.env.REACT_APP_BE_APIGEE_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.APIGEE] = new WebSocket(`${process.env.REACT_APP_BE_APIGEE_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.APIGEE].onopen = () => dispatch({type: CONNECT_APIGEE_WEBSOCKET_SUCCESS});
            webSockets[modules.APIGEE].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.APIGEE_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : alertMessages.APIGEE_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.APIGEE].onclose = async () =>
                webSockets[modules.APIGEE] = await reinitializeClosedSocket(webSockets[modules.APIGEE], process.env.REACT_APP_BE_APIGEE_WS_URL, action, dispatch);
        }
        if (action.apigeeWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.APIGEE, alertMessages.APIGEE_BE_UNREACHABLE);
        }
        return next(action);
    }
};

const reinitializeClosedSocket = async (socket, socketUrl, action, dispatch) => {
    let result = socket;

    const authenticatedUser = await getAuthenticatedUser();
    if (authenticatedUser && authenticatedUser.expired) {
        dispatch(infoAlert(
            alertMessages.TOKEN_EXPIRED,
            {
                buttonLabel: buttonMessages.YES,
                handleClick: async function () {
                    await authenticateAndRedirect();
                }
            },
            {},
            infoAlertUniqueId
        ));
    } else {
        const {onopen, onmessage, onerror, onclose} = result;
        result = new WebSocket(`${socketUrl}?Auth=${authenticatedUser.access_token}`);
        Object.assign(result, {onopen, onmessage, onerror, onclose});
    }

    return result;
};

/**
 * The purpose of this function is to keep checking whether the WebSocket is properly connected. It checks only for
 * a certain amount of attempts (REACT_APP_WS_CONN_ATTEMPTS) and waits for certain amount of milliseconds before each
 * attempt (REACT_APP_WS_CONN_INTERVAL). If the connection is established in time, the request is sent to the WebSocket.
 * If not, error alert is dispatched.
 *
 * Why do we do this? Because on some environments it takes some time for each WebSocket to be connected (cca. 2 seconds)
 * and if we don't wait for it somehow, the request is just lost (and frontend keeps spinning the wheel forever).
 * But we cannot handle this only during initialization of the application because user can also access a particular
 * URL address (like for example ticket detail). So we need some general solution on one place for all WebSocket
 * requests.
 *
 * If you have a more elegant solution to this problem, feel free to share your idea.
 *
 * @param dispatch
 * @param getState
 * @param action
 * @param module
 * @param alertMessage
 * @param counter
 * @returns {Promise<void>}
 */
const attemptWsCall = async (dispatch, getState, action, module, alertMessage, counter = 0) => {
    const isConnected = get(getState(), `websocket.isConnected.${module}`, false);

    if (webSockets[module] && isConnected) {
        webSockets[module].send(JSON.stringify({
            command: action.command,
            message: {correlationId: new Date(), ...action.payload}
        }));
    } else if (counter < process.env.REACT_APP_WS_CONN_ATTEMPTS) {
        await (interval => new Promise(resolve => setTimeout(resolve, interval)))(process.env.REACT_APP_WS_CONN_INTERVAL);
        await attemptWsCall(dispatch, getState, action, module, alertMessage, counter + 1);
    } else {
        dispatch(errorAlert(alertMessage));
    }
};
