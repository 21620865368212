export const HOME_PATH = '/';

export const ADMINISTRATION_PATH = '/administration';
export const DISTRIBUTION_PATH = '/distribution';
export const COMMERCIAL_PATH = '/commercial';
export const MARKETING_PATH = '/marketing';

// DISTRIBUTION
// administration
export const ADMINISTRATION_DISTRIBUTION_ADMIN_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/admin`;
export const ADMINISTRATION_DISTRIBUTION_DEALER_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/dealer`;
export const ADMINISTRATION_DISTRIBUTION_EDITOR_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/editor`;
export const ADMINISTRATION_DISTRIBUTION_EXTERNAL_EDITOR_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/externalEditor`;
export const ADMINISTRATION_DISTRIBUTION_TICKET_SETTING_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/ticketSetting`;
export const ADMINISTRATION_DISTRIBUTION_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/notification`;
export const ADMINISTRATION_DISTRIBUTION_SUBJECT_SETTING_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/subjectSetting`;
export const ADMINISTRATION_DISTRIBUTION_FREQUENT_QUESTION_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/frequentQuestion`;
export const ADMINISTRATION_DISTRIBUTION_PRESET_RESPONSE_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/presetResponse`;
export const ADMINISTRATION_DISTRIBUTION_WORKING_HOURS_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/workingHours`;
export const ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH = `${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}/businessUnit`;
export const ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_NEW_PATH = `${ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH}/new`;
export const ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_LIST_PATH = `${ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH}/list`;
export const ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_ASSIGNMENT_PATH = `${ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH}/assignment`;
// distribution
export const DISTRIBUTION_NEW_PATH = `${DISTRIBUTION_PATH}/new`;
export const DISTRIBUTION_VIEW_PATH = `${DISTRIBUTION_PATH}/view`;
export const DISTRIBUTION_LIST_PATH = `${DISTRIBUTION_PATH}/list`;
export const DISTRIBUTION_HISTORY_PATH = `${DISTRIBUTION_PATH}/history`;
export const DISTRIBUTION_FULL_TEXT_SEARCH_PATH = `${DISTRIBUTION_PATH}/fullTextSearch`;
export const DISTRIBUTION_EXPORT_PATH = `${DISTRIBUTION_PATH}/export`;
export const DISTRIBUTION_NOTIFICATION_PATH = `${DISTRIBUTION_PATH}/notification`;
// tickets
export const DISTRIBUTION_NEW_TICKET_PATH = `${DISTRIBUTION_NEW_PATH}/distributionTicket`;

// COMMERCIAL
// administration
export const ADMINISTRATION_COMMERCIAL_ADMIN_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/admin`;
export const ADMINISTRATION_COMMERCIAL_DEALER_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/dealer`;
export const ADMINISTRATION_COMMERCIAL_EDITOR_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/editor`;
export const ADMINISTRATION_COMMERCIAL_TICKET_SETTING_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/ticketSetting`;
export const ADMINISTRATION_COMMERCIAL_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/notification`;
export const ADMINISTRATION_COMMERCIAL_SUBJECT_SETTING_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/subjectSetting`;
export const ADMINISTRATION_COMMERCIAL_SUBJECT_OFFER_FOR_EMPLOYEES = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/subjectOfferForEmployeesSetting`;
export const ADMINISTRATION_COMMERCIAL_FREQUENT_QUESTION_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/frequentQuestion`;
export const ADMINISTRATION_COMMERCIAL_PRESET_RESPONSE_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/presetResponse`;
export const ADMINISTRATION_COMMERCIAL_WORKING_HOURS_PATH = `${ADMINISTRATION_PATH}${COMMERCIAL_PATH}/workingHours`;
// commercial
export const COMMERCIAL_NEW_PATH = `${COMMERCIAL_PATH}/new`;
export const COMMERCIAL_VIEW_PATH = `${COMMERCIAL_PATH}/view`;
export const COMMERCIAL_LIST_PATH = `${COMMERCIAL_PATH}/list`;
export const COMMERCIAL_HISTORY_PATH = `${COMMERCIAL_PATH}/history`;
export const COMMERCIAL_FULL_TEXT_SEARCH_PATH = `${COMMERCIAL_PATH}/fullTextSearch`;
export const COMMERCIAL_EXPORT_PATH = `${COMMERCIAL_PATH}/export`;
export const COMMERCIAL_NOTIFICATION_PATH = `${COMMERCIAL_PATH}/notification`;
// tickets
export const COMMERCIAL_NEW_TICKET_PATH = `${COMMERCIAL_NEW_PATH}/commercialTicket`;
export const COMMERCIAL_NEW_OFFER_FOR_EMPLOYEES_PATH = `${COMMERCIAL_NEW_PATH}/offerForEmployees`;

// MARKETING
// administration
export const ADMINISTRATION_MARKETING_ADMIN_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/admin`;
export const ADMINISTRATION_MARKETING_DEALER_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/dealer`;
export const ADMINISTRATION_MARKETING_EDITOR_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/editor`;
export const ADMINISTRATION_MARKETING_TICKET_SETTING_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/ticketSetting`;
export const ADMINISTRATION_MARKETING_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/notification`;
export const ADMINISTRATION_MARKETING_SUBJECT_SETTING_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/subjectSetting`;
export const ADMINISTRATION_MARKETING_FREQUENT_QUESTION_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/frequentQuestion`;
export const ADMINISTRATION_MARKETING_PRESET_RESPONSE_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/presetResponse`;
export const ADMINISTRATION_MARKETING_WORKING_HOURS_PATH = `${ADMINISTRATION_PATH}${MARKETING_PATH}/workingHours`;
export const ADMINISTRATION_MARKETING_GENERAL_QUESTION_PATH = `${ADMINISTRATION_MARKETING_SUBJECT_SETTING_PATH}/generalQuestion`;
export const ADMINISTRATION_MARKETING_SPARE_PART_PRICE_VERIFICATION_PATH = `${ADMINISTRATION_MARKETING_SUBJECT_SETTING_PATH}/sparePartPriceVerification`;
export const ADMINISTRATION_MARKETING_SPARE_PART_NAME_VERIFICATION_PATH = `${ADMINISTRATION_MARKETING_SUBJECT_SETTING_PATH}/sparePartNameVerification`;
// marketing
export const MARKETING_NEW_PATH = `${MARKETING_PATH}/new`;
export const MARKETING_VIEW_PATH = `${MARKETING_PATH}/view`;
export const MARKETING_LIST_PATH = `${MARKETING_PATH}/list`;
export const MARKETING_HISTORY_PATH = `${MARKETING_PATH}/history`;
export const MARKETING_FULL_TEXT_SEARCH_PATH = `${MARKETING_PATH}/fullTextSearch`;
export const MARKETING_EXPORT_PATH = `${MARKETING_PATH}/export`;
export const MARKETING_NOTIFICATION_PATH = `${MARKETING_PATH}/notification`;
//tickets
export const MARKETING_NEW_GENERAL_QUESTION_PATH = `${MARKETING_NEW_PATH}/generalQuestion`;
export const MARKETING_NEW_SPARE_PART_PRICE_VERIFICATION_PATH = `${MARKETING_NEW_PATH}/sparePartPriceVerification`;
export const MARKETING_NEW_SPARE_PART_NAME_VERIFICATION_PATH = `${MARKETING_NEW_PATH}/sparePartNameVerification`;
