import React, {Component} from 'react';
import PropTypes from 'prop-types';
import App from './App';
import Bowser from 'bowser';
import {REDIR_TIME_SESSION_STORAGE} from '../utils/auth';

const bowserInfo = Bowser.getParser(window.navigator.userAgent);

class PreLoadApp extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (sessionStorage.hasOwnProperty(REDIR_TIME_SESSION_STORAGE)) {
            let value = JSON.parse(sessionStorage.getItem(REDIR_TIME_SESSION_STORAGE));
            if (value.timestamp + 900000 >= new Date().getTime() && value.count >= 3) {
                return <div>
                    <h1>Too many IDP requests!</h1>
                    <p>Please wait a few minutes and try again. If the problem still persists, contact your support.</p>
                </div>;
            }
        }
        if (bowserInfo && !!(bowserInfo.isBrowser('internet explorer'))) {
            return <div>
                <h1>Internet Explorer is an outdated browser</h1>
                <p>For a safer and faster user experience use a modern browser like Chrome, Firefox, Safari, or
                    Edge.</p>
            </div>;
        }
        const {store} = this.props;
        return (
            <App store={store}/>
        );
    }
}

PreLoadApp.propTypes = {
    store: PropTypes.any.isRequired,
};

export default PreLoadApp;
