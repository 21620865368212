import {
    DISTRIBUTION_FORM,
    DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    DISTRIBUTION_TICKET_STATUS_NEW,
    DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_DEALER,
    DISTRIBUTION_TICKET_STATUS_REOPEN,
    DISTRIBUTION_TICKET_STATUS_CLOSED,
    GENERAL_QUESTION_FORM,
    SPARE_PART_PRICE_VERIFICATION_FORM,
    SPARE_PART_NAME_VERIFICATION_FORM,
    MARKETING_TICKET_STATUS_NEW,
    MARKETING_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    MARKETING_TICKET_STATUS_IN_PROGRESS_DEALER,
    MARKETING_TICKET_STATUS_REOPEN,
    MARKETING_TICKET_STATUS_CLOSED,
    COMMERCIAL_FORM,
    COMMERCIAL_TICKET_STATUS_NEW,
    COMMERCIAL_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    COMMERCIAL_TICKET_STATUS_IN_PROGRESS_DEALER,
    COMMERCIAL_TICKET_STATUS_REOPEN,
    COMMERCIAL_TICKET_STATUS_CLOSED,
    OFFER_FOR_EMPLOYEES_FORM,
    COMMERCIAL_TICKET_STATUS_WORK_IN_PROGRESS,
    DISTRIBUTION_TICKET_STATUS_WORK_IN_PROGRESS,
    MARKETING_TICKET_STATUS_WORK_IN_PROGRESS,
} from './formConstants';
import {HIGH, LOW, MAJOR, MEDIUM} from './levelConstants';

export const ALL_COUNTRIES_VALUE = 'ALL';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_NONE = '';
export const REQUEST_PARAM_DELIM = ':';

export const domains = {
    ADMINISTRATION: 'ADMINISTRATION',
    DISTRIBUTION: 'DISTRIBUTION',
    COMMERCIAL: 'COMMERCIAL',
    MARKETING: 'MARKETING',
};

export const apiMappingKey = {
    [domains.ADMINISTRATION]: 'administration',
    [domains.DISTRIBUTION]: 'distribution',
    [domains.COMMERCIAL]: 'commercial',
    [domains.MARKETING]: 'marketing'
};

export const getSubjectKey = {
    distribution: 'DISTRIBUTION_FORM',
    commercial: 'COMMERCIAL_FORM',
    offerForEmployees: 'OFFER_FOR_EMPLOYEES_FORM',
    marketingQuestion: 'GENERAL_QUESTION',
    priceVerification: 'SPARE_PART_PRICE_VERIFICATION',
    nameVerification: 'SPARE_PART_NAME_VERIFICATION',
};

export const formNameFromSubjectKey = {
    DISTRIBUTION_FORM: 'distribution',
    COMMERCIAL_FORM: 'commercial',
    OFFER_FOR_EMPLOYEES_FORM: 'offerForEmployees',
    GENERAL_QUESTION: 'marketingQuestion',
    SPARE_PART_PRICE_VERIFICATION: 'priceVerification',
    SPARE_PART_NAME_VERIFICATION: 'nameVerification',
};

export function getFormTypes(domain) {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return [
                DISTRIBUTION_FORM,
            ];
        }
        case domains.COMMERCIAL: {
            return [
                COMMERCIAL_FORM,
                OFFER_FOR_EMPLOYEES_FORM,
            ];
        }
        case domains.MARKETING: {
            return [
                GENERAL_QUESTION_FORM,
                SPARE_PART_PRICE_VERIFICATION_FORM,
                SPARE_PART_NAME_VERIFICATION_FORM,
            ];
        }
        default: {
            return [];
        }
    }
}

export function getFormStates(domain) {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return [
                DISTRIBUTION_TICKET_STATUS_NEW,
                DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
                DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_DEALER,
                DISTRIBUTION_TICKET_STATUS_WORK_IN_PROGRESS,
                DISTRIBUTION_TICKET_STATUS_REOPEN,
                DISTRIBUTION_TICKET_STATUS_CLOSED,
            ];
        }
        case domains.COMMERCIAL: {
            return [
                COMMERCIAL_TICKET_STATUS_NEW,
                COMMERCIAL_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
                COMMERCIAL_TICKET_STATUS_IN_PROGRESS_DEALER,
                COMMERCIAL_TICKET_STATUS_WORK_IN_PROGRESS,
                COMMERCIAL_TICKET_STATUS_REOPEN,
                COMMERCIAL_TICKET_STATUS_CLOSED,
            ];
        }
        case domains.MARKETING: {
            return [
                MARKETING_TICKET_STATUS_NEW,
                MARKETING_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
                MARKETING_TICKET_STATUS_IN_PROGRESS_DEALER,
                MARKETING_TICKET_STATUS_WORK_IN_PROGRESS,
                MARKETING_TICKET_STATUS_REOPEN,
                MARKETING_TICKET_STATUS_CLOSED,
            ];
        }
        default: {
            return [];
        }
    }
}

export const modules = {
    ADMINISTRATION: domains.ADMINISTRATION,
    DISTRIBUTION: domains.DISTRIBUTION,
    COMMERCIAL: domains.COMMERCIAL,
    MARKETING: domains.MARKETING,
    APIGEE: 'APIGEE',
};

export const brandValues = [
    'RENAULT',
    'DACIA',
    'NISSAN',
    'ALPINE',
];

export const brandOptions = {
    PL: [
        'RENAULT',
        'DACIA',
        'NISSAN',
        'ALPINE',
    ],
    SE: [
        'RENAULT',
        'DACIA',
        'NISSAN',
        'ALPINE',
    ],
    CZ: [
        'RENAULT',
        'DACIA',
        'NISSAN',
        'ALPINE',
    ],
    SK: [
        'RENAULT',
        'DACIA',
        'NISSAN',
        'ALPINE',
    ],
    HU: [
        'RENAULT',
        'DACIA',
        'NISSAN',
        'ALPINE',
    ],
    FR: [
        'RENAULT',
        'DACIA',
        'NISSAN',
        'ALPINE',
    ],
    DEFAULT: [
        'RENAULT',
        'DACIA',
        'NISSAN',
        'ALPINE',
    ]
};

export const typeOfPurchaseOptions = {
    PL: [
        'RENT',
        'PURCHASE',
    ],
    DEFAULT: [
        'RENT',
        'PURCHASE',
    ]
};

export const employeeOfOptions = {
    PL: [
        'RP',
        'DEALER_NETWORK',
    ],
    DEFAULT: [
        'RP',
        'DEALER_NETWORK',
    ]
};

export const getSegmentOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'K', 'L', 'M', 'P', 'R', 'S', 'T', 'U', 'Z',];

export const languages = ['cs', 'da', 'de', 'en', 'es', 'fr', 'hr', 'hu', 'it', 'nl', 'pl', 'pt', 'ro', 'sk', 'sl', 'sr', 'sv'];

export const locations = [
    'cs-CZ',
    'de-DE',
    'en-GB',
    'en-IE',
    'es-ES',
    'fr-FR',
    'it-IT',
    'pl-PL',
    'pt-PT',
    'sl-SI',
    'sv-SE',
    'sk-SK',
    'hu-HU',
    'nl-NL',
];

export const countryLanguageMapping = {
    AT: 'de',
    CZ: 'cs',
    DE: 'de',
    DK: 'da',
    ES: 'es',
    FI: 'FI',
    FR: 'fr',
    HR: 'hr',
    HU: 'hu',
    IT: 'it',
    NL: 'nl',
    PL: 'pl',
    PT: 'pt',
    RO: 'ro',
    SE: 'sv',
    SI: 'sl',
    SK: 'sk',
    GB: 'en',
    DEFAULT: 'en',
};

export const languageCountryMapping = {
    cs: 'CZ',
    de: 'DE',
    da: 'DK',
    es: 'ES',
    fi: 'FI',
    fr: 'FR',
    hr: 'HR',
    hu: 'HU',
    it: 'IT',
    nl: 'NL',
    pl: 'PL',
    pt: 'PT',
    ro: 'RO',
    sv: 'se',
    sl: 'SI',
    sk: 'SK',
    en: 'GB',
    DEFAULT: 'DEFAULT',
};

export const countryGroups = {
    AR: 'AR',
    AT: 'AT',
    BA: 'BA',
    BE: 'BE',
    CH: 'CH',
    CZ: 'CZ',
    DE: 'DE',
    DK: 'DK',
    EE: 'EE',
    ES: 'ES',
    FI: 'FI',
    FR: 'FR',
    HR: 'HR',
    HU: 'HU',
    IE: 'IE',
    IT: 'IT',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    MA: 'MA',
    ME: 'ME',
    MK: 'MK',
    NL: 'NL',
    NO: 'NO',
    PL: 'PL',
    PT: 'PT',
    RO: 'RO',
    RS: 'RS',
    SE: 'SE',
    SI: 'SI',
    SK: 'SK',
    GB: 'GB',
    XK: 'XK',
    IMP: 'IMP'
};

export const modelValues = {
    PL: {
        RENAULT: [
            'CL5 : CLIO 5',
            'FF1 : MEGANE GRANDCOUPE',
            'DF1 : EXPRESS',
            'KF1 : KANGOO VU',
            'KK1 : KANGOO VP',
            'MB4 : MEGANE',
            'MK4 : MEGANE GRANDTOUR',
            'TRP : TRAFIC PASSANGER',
            'TRU : TRAFIC FURGON',
            'ZGH : KOLEOS',
            'ZOE : ZOE',
            'R3O : MASTER PODWOZIE',
            'R3U : MASTER FURGON',
            'JL1 : ARKANA',
            'AR1 : SYMBIOZ',
            'DN1 : RAFALE',
            'RN1 : ESPACE',
            'ZH1 : SCENIC E-TECH ELECTRIC',
            'ZO1 : MEGANE E-TECH ELECTRIC',
        ],
        DACIA: [
            'SA2 : SANDERO',
            'LI1 : NOWY LOGAN',
            'BI1 : NOWE SANDERO',
            'S1E : SPRING',
            'JD1 : DUSTER',
            'DU3 : NOWY DUSTER',
        ],
        ALPINE: [
            'AL1 : ALPINE',
        ]
    },
    LT: {
        RENAULT: [
            'CL5 : CLIO 5',
            'FF1 : MEGANE GRANDCOUPE',
            'DF1 : EXPRESS',
            'KF1 : KANGOO VU',
            'KK1 : KANGOO VP',
            'MB4 : MEGANE',
            'MK4 : MEGANE GRANDTOUR',
            'TRP : TRAFIC PASSANGER',
            'TRU : TRAFIC FURGON',
            'ZGH : KOLEOS',
            'ZOE : ZOE',
            'R3O : MASTER PODWOZIE',
            'R3U : MASTER FURGON',
            'JL1 : ARKANA',
            'AR1 : SYMBIOZ',
            'DN1 : RAFALE',
            'RN1 : ESPACE',
            'ZH1 : SCENIC E-TECH ELECTRIC',
            'ZO1 : MEGANE E-TECH ELECTRIC',
        ],
        DACIA: [
            'SA2 : SANDERO',
            'LI1 : NOWY LOGAN',
            'BI1 : NOWE SANDERO',
            'S1E : SPRING',
            'JD1 : DUSTER',
            'DU3 : NOWY DUSTER',
        ],
        ALPINE: [
            'AL1 : ALPINE',
        ]
    },
    LV: {
        RENAULT: [
            'CL5 : CLIO 5',
            'FF1 : MEGANE GRANDCOUPE',
            'DF1 : EXPRESS',
            'KF1 : KANGOO VU',
            'KK1 : KANGOO VP',
            'MB4 : MEGANE',
            'MK4 : MEGANE GRANDTOUR',
            'TRP : TRAFIC PASSANGER',
            'TRU : TRAFIC FURGON',
            'ZGH : KOLEOS',
            'ZOE : ZOE',
            'R3O : MASTER PODWOZIE',
            'R3U : MASTER FURGON',
            'JL1 : ARKANA',
            'AR1 : SYMBIOZ',
            'DN1 : RAFALE',
            'RN1 : ESPACE',
            'ZH1 : SCENIC E-TECH ELECTRIC',
            'ZO1 : MEGANE E-TECH ELECTRIC',
        ],
        DACIA: [
            'SA2 : SANDERO',
            'LI1 : NOWY LOGAN',
            'BI1 : NOWE SANDERO',
            'S1E : SPRING',
            'JD1 : DUSTER',
            'DU3 : NOWY DUSTER',
        ],
        ALPINE: [
            'AL1 : ALPINE',
        ]
    },
    EE: {
        RENAULT: [
            'CL5 : CLIO 5',
            'FF1 : MEGANE GRANDCOUPE',
            'DF1 : EXPRESS',
            'KF1 : KANGOO VU',
            'KK1 : KANGOO VP',
            'MB4 : MEGANE',
            'MK4 : MEGANE GRANDTOUR',
            'TRP : TRAFIC PASSANGER',
            'TRU : TRAFIC FURGON',
            'ZGH : KOLEOS',
            'ZOE : ZOE',
            'R3O : MASTER PODWOZIE',
            'R3U : MASTER FURGON',
            'JL1 : ARKANA',
            'AR1 : SYMBIOZ',
            'DN1 : RAFALE',
            'RN1 : ESPACE',
            'ZH1 : SCENIC E-TECH ELECTRIC',
            'ZO1 : MEGANE E-TECH ELECTRIC',
        ],
        DACIA: [
            'SA2 : SANDERO',
            'LI1 : NOWY LOGAN',
            'BI1 : NOWE SANDERO',
            'S1E : SPRING',
            'JD1 : DUSTER',
            'DU3 : NOWY DUSTER',
        ],
        ALPINE: [
            'AL1 : ALPINE',
        ]
    },
    CZ: {
        RENAULT: [
            'CL5 : CLIO 5',
            'FF1 : MEGANE GRANDCOUPE',
            'DF1 : EXPRESS',
            'KF1 : KANGOO VU',
            'KK1 : KANGOO VP',
            'MB4 : MEGANE',
            'MK4 : MEGANE GRANDTOUR',
            'TRP : TRAFIC PASSANGER',
            'TRU : TRAFIC FURGON',
            'ZGH : KOLEOS',
            'ZOE : ZOE',
            'R3O : MASTER PODWOZIE',
            'R3U : MASTER FURGON',
            'JL1 : ARKANA',
            'AR1 : SYMBIOZ',
            'DN1 : RAFALE',
            'RN1 : ESPACE',
            'ZH1 : SCENIC E-TECH ELECTRIC',
            'ZO1 : MEGANE E-TECH ELECTRIC',
        ],
        DACIA: [
            'SA2 : SANDERO',
            'LI1 : NOWY LOGAN',
            'BI1 : NOWE SANDERO',
            'S1E : SPRING',
            'JD1 : DUSTER',
            'DU3 : NOWY DUSTER',
        ],
        ALPINE: [
            'AL1 : ALPINE',
        ]
    },
    SK: {
        RENAULT: [
            'CL5 : CLIO 5',
            'FF1 : MEGANE GRANDCOUPE',
            'DF1 : EXPRESS',
            'KF1 : KANGOO VU',
            'KK1 : KANGOO VP',
            'MB4 : MEGANE',
            'MK4 : MEGANE GRANDTOUR',
            'TRP : TRAFIC PASSANGER',
            'TRU : TRAFIC FURGON',
            'ZGH : KOLEOS',
            'ZOE : ZOE',
            'R3O : MASTER PODWOZIE',
            'R3U : MASTER FURGON',
            'JL1 : ARKANA',
            'AR1 : SYMBIOZ',
            'DN1 : RAFALE',
            'RN1 : ESPACE',
            'ZH1 : SCENIC E-TECH ELECTRIC',
            'ZO1 : MEGANE E-TECH ELECTRIC',
        ],
        DACIA: [
            'SA2 : SANDERO',
            'LI1 : NOWY LOGAN',
            'BI1 : NOWE SANDERO',
            'S1E : SPRING',
            'JD1 : DUSTER',
            'DU3 : NOWY DUSTER',
        ],
        ALPINE: [
            'AL1 : ALPINE',
        ]
    },
    HU: {
        RENAULT: [
            'CK4 : CLIO GRANDTOUR',
            'CL4 : CLIO GENERATION',
            'CS4 : CLIO SOCIETE',
            'CL5 : CLIO',
            'CP1 : CAPTUR',
            'FDL : TALISMAN',
            'FDK : TALISMAN GRANDTOUR',
            'FEH : KADJAR',
            'FF1 : MEGANE GRANDCOUPE',
            'KQ2 : KANGOO Z.E.',
            'KU2 : KANGOO EXPRESS',
            'DF1 : EXPRESS',
            'KF1 : Új KANGOO',
            'MB4 : MEGANE',
            'MK4 : MEGANE GRANDTOUR',
            'R3O : MASTER ALVÁZ',
            'R3U : MASTER FURGON',
            'SL4 : SCENIC',
            'SP5 : Espace',
            'TRP : TRAFIC szgk',
            'TRU : TRAFIC',
            'ZGH : KOLEOS',
            'ZOE : ZOE',
            'JL1 : ARKANA'
        ],
        DACIA: [
            '52B : SANDERO',
            '52K : LOGAN MCV',
            '52L : LOGAN',
            '67P : DOKKER VP',
            '67F : DOKKER VU',
            '92J : LODGY',
            'Ll1 : Új LOGAN',
            'BI1 : Új SANDERO',
            'S1E : SPRING',
            'JD1 : DUSTER',
        ],
    },
    NL: {
        RENAULT: [
            '2WE : TWINGO E-TECH ELECTRIC',
            'CL5 : CLIO',
            'CP1 : CAPTUR',
            'DF1 : EXPRESS',
            'DN1 : RAFALE',
            'HN1 : AUSTRAL',
            'JL1 : ARKANA',
            'KF1 : KANGOO',
            'KV1 : KANGOO E-TECH ELECTRIC',
            'M1E : MASTER E-TECH ELECTRIC',
            'MK4 : MEGANE ESTATE',
            'R3O : MASTER OPEN TRANSPORT',
            'R3U : MASTER',
            'R5E : R5',
            'RN1 : ESPACE',
            'T2E : TRAFIC E-TECH ELECTRIC',
            'TRU : TRAFIC',
            'ZH1 : SCENIC',
            'ZOE : ZOE E-TECH ELECTRIC',
            'ZO1 : MEGANE E-TECH ELECTRIC',
        ],
        DACIA: [
            'BI1 : SANDERO',
            'JD1 : DUSTER',
            'RI1 : JOGGER',
            'S1E : SPRING',
        ],
        ALPINE: [
            'AL1 : ALPINE A110',
        ]
    },
};

export const getPriority = [
    LOW,
    MEDIUM,
    HIGH,
    MAJOR
];

export const getContact = [
    "PHONE",
    "EMAIL",
    "OTHER"
];
